<template>
  <div class="pay-footer">
    <div class='footer' v-if='value.hidden==1'>
        <div class="left" > <a :href="'tel:'+ value.mobile "><img src="../../assets/phone.png" alt="">来电咨询</a> </div>
        <div class="right" @click='b()' > <img src="../../assets/weixin.png" alt=""><span id="copy_content" >{{tel}}</span></div>
    </div>
    <van-row v-else>
      <van-col span="12" style="padding-left:0.8rem">
        <div class="price-tag" v-if='value.is_free==1'>免费</div>
        <div class="price-tag" v-if='value.is_free==0'>￥{{value.discount_price}}</div>
        <div class="price-count">已报名人数:{{value.buy_count}}</div>
      </van-col>
      <van-col span="12">
        <van-button plain @click="signUp" v-if='value.is_buy==0'>立即报名</van-button>
        <van-button plain @click="download" v-if='value.is_buy==1'>已报名</van-button>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import { Toast } from 'vant';
import request from '../../plugin/request'
import Cookies from 'js-cookie';
export default {
  props:{
      value:{}
  },
  data() {
    return { 
      tel:'添加微信',
      id:[]
    }
  },
  created(){
  },
  mounted(){
    let currentQuery = this.$route.query;
    this.id = currentQuery.id;
    this.setToken()
  },
  methods:{
    setToken(token) {
       var tokens = localStorage.getItem('token');
       
       console.log(this.value)
       if(tokens!=null) {
          return Cookies.set('Token', tokens)
       } else {
           return Cookies.set('Token', token)
       }
    },
    b() {
   if(this.tel=='添加微信') {
           this.tel=this.value.wechat_ID
      }
    this.timer = setTimeout(this.copywx, 500);
    },
      copywx() {
         let range = document.createRange();
        range.selectNode(document.getElementById('copy_content'));
        let selection = window.getSelection();
        if(selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        window.location.href="weixin://"; 
    },
    // 点击提示
    //  download() {
    //   // this.download=true
    //    this.$router.push({ path:'/download'  })
    // },
    // 支付界面
   async  signUp() {
       var tokens = localStorage.getItem('token');
          var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)=="micromessenger") {
           Toast('点击右上角"···"选择用浏览器打开');  
      } else {
         if(tokens!=null) {
         if (this.value.discount_price=='0.00'||this.value.is_free==1) {
               const data =await request.post('/app/course/joinCourse',{
              course_id:this.value.id
            })
            console.log(data)
            if(data.code==200) {
              this.$router.push({ path:'/?type=1'})
            }else if(data.code==505)  {
              this.$router.push({ path:'/login?url='+this.value.url+'&&id='+this.value.id+'&&share_id='+this.value.share_id})
            } else {
              this.$router.push({ path:'/fail?id='+this.value.id+'&url='+this.value.url+'&&share_id='+this.value.share_id})
            }
         } else {
          this.$router.push({ path:'/payment?id='+this.id+'&url='+this.value.url+'&discount_price='+this.value.discount_price+'&group=0'+'&&share_id='+this.value.share_id})

         
         }
       }  else {
          Toast('请先登录！');  
          this.$router.push({ path:'/login?url='+this.value.url+'&&id='+this.value.id+'&&share_id='+this.value.share_id})
       }
      }
      
      // this.download=true
    
    },
    download() {
        this.$router.push({ path:'/download'})
    }
  }
};
</script>
<style lang="less" scoped>
.pay-footer {
  padding: 4px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 1.31rem;
  background-color: #fff;
 
  z-index: 10;
  .footer  {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top:.21rem;
    
    div {
      width: 3.2rem;
      height: 0.91rem;
      text-align: center;
      font-size: .4rem;
      border:.01rem solid #2775FF;
      border-radius: .53rem;
      line-height: 0.91rem ;
      img {
       vertical-align:middle;
       margin-right:.07rem
      }

     
    }



    .left {
    color:#2775FF ;
    margin-right: .67rem;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      color:#2775FF ;
    }
      img {
        width: .49rem;
        height: .57rem;
      }
    }

     .right {
       background-color: #2775FF;
       color: #fff;
         margin-left: .67rem;
      img {
        width: .56rem;
        height: .45rem;
      }
    }
  }

  .price-tag {
    color: #fc6041;
    font-size: 0.43rem;
    margin-top:0.13rem
  }
  .price-count {
    color: #99a4bb;
    font-size: 0.32rem;
    margin-top:0.13rem;
    
  } 
  .van-col {
    position: relative;
    height:1.31rem;;
  }

  .van-button  {
    width: 3.2rem;
    height: 0.91rem;
    border-radius: 0.4rem;
    color: #2775ff;
    text-align: center;
    font-size: 0.4rem;
    box-sizing: border-box;
    border:1px solid #2775ff;
    background-color: #fff;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    right:0.4rem;

    
  }
}
</style>